/* eslint-disable @typescript-eslint/no-explicit-any */
import {locale, t} from 'svelte-i18n';
import {logError} from '$lib/utils/sentry';
import {get} from 'svelte/store';
import {getObjectDefinition} from '../definitions';

import {
	format as formatFromApiFieldFormatter,
	getFieldTitle as getFieldTitleFromAPiFieldFormatter,
	formatCurrency as formatCurrencyFromApiFieldFormatter,
	formatDateField,
	type UnderscoreLocale,
	type ApiFieldFormatterContext
} from '@blg/api-field-formatter';

import dependenciesStore, {type Unit} from '$stores/dependencies';
import {getPluralKey, translations} from '$lib/i18n/translations';
import type {AllData, NotConsultedItem} from '$types/widgets';

export function parseUnit(
	value: number,
	unitName: string,
	displayAbbrevation: boolean = true,
	nbDigit: number = 0
): string {
	const dependencies = get(dependenciesStore);
	const units = dependencies['units'] as Unit[];
	const unit: Unit | undefined = units?.find((u) => u.name === unitName);
	const toDisplay = unit
		? (value / unit.coefficient).toFixed(nbDigit)
		: String(value);

	return `${toDisplay}${displayAbbrevation ? ` ${unit?.abbreviation}` : ''}`;
}

export function unParseUnit(value: number, unitName: string): number {
	const dependencies = get(dependenciesStore);
	const units = dependencies['units'] as Unit[];
	const unit: Unit | undefined = units.find((u) => u.name === unitName);
	return unit ? value * unit.coefficient : value;
}

/* Format field from api-field-formatter lib */
export function format(
	location: string,
	fieldPath: string,
	value: any,
	formatKey?: string | undefined
): string {
	try {
		const objectDef = getObjectDefinition(location);

		const currentLocale: UnderscoreLocale =
			(get(locale)?.replace('-', '_') as UnderscoreLocale) || 'en_GB';

		const context: ApiFieldFormatterContext = {
			locale: currentLocale,
			objectDefinition: objectDef
		};

		return formatFromApiFieldFormatter(
			context,
			fieldPath,
			value,
			formatKey
		);
	} catch (err) {
		logError(err);
		return value;
	}
}

/* Format field  title from api-field-formatter lib */
export function getFieldTitle(location: string, fieldPath: string): string {
	const objectDef = getObjectDefinition(location);

	const currentLocale: UnderscoreLocale =
		(get(locale)?.replace('-', '_') as UnderscoreLocale) || 'en_GB';
	const context: ApiFieldFormatterContext = {
		locale: currentLocale,
		objectDefinition: objectDef
	};

	return getFieldTitleFromAPiFieldFormatter(context, fieldPath);
}

/* Format currency from api-field-formatter lib */
export function formatCurrency(
	amount: any,
	currency: any,
	formatKey: 'hideDecimal' | 'decimalIfExists' | 'showDecimal'
): string {
	const currentLocale: UnderscoreLocale =
		(get(locale)?.replace('-', '_') as UnderscoreLocale) || 'en_GB';

	return formatCurrencyFromApiFieldFormatter(
		amount,
		currency,
		currentLocale,
		formatKey
	);
}

export function snakeToCamel(input: string) {
	return input.replace(/_([a-z])/g, (_, label) => label.toUpperCase());
}

export function capitalize(str: string): string {
	return str.charAt(0).toUpperCase() + str.slice(1);
}

export function camelCase(input: string) {
	return input
		.replace(/[_\s]([a-z])/g, function (match, group) {
			return group.toUpperCase();
		})
		.replace(/^[A-Z]/, function (match) {
			return match.toLowerCase();
		});
}

export function snakeCase(input: string) {
	return input
		.replace(/([A-Z])/g, function (match) {
			return '_' + match.toLowerCase();
		})
		.replace(/^_/, '') // remove initial undesrcore
		.replace(/ /g, '_'); // replace initial spaces by underscore
}
/**
 * Format currency from api-field-formatter lib
 *
 * Equivalents :
 * - 'relative': distanceToNow with suffixe
 * - 'shortRelative': distanceToNow without suffixe
 * - 'blgM': P
 * - 'blgL': PP
 * - 'blgXl': PPPP
 * - [custom]: date-fns format
 * */
export function formatDate(value: any, formatKey: string | null = 'blgM') {
	const currentLocale: UnderscoreLocale =
		(get(locale)?.replace('-', '_') as UnderscoreLocale) || 'en_GB';

	return formatDateField(value, currentLocale, formatKey as string);
}

export function formatDateToApiIso8601Date(date: Date): string {
	// format date to YYYY-MM-DDTHH:mm:ss+HHmm
	const isoDate = date.toISOString();
	const dateWithoutTimezone = isoDate.split('.')[0];
	return `${dateWithoutTimezone}+0000`;
}

export function formatDateToISO(date: Date): string {
	return date.toISOString().split('T')[0];
}

const DURATION_MAP = {
	Y: {
		coef: 3_110_400_000,
		translationKey: 'year'
	},
	M: {
		coef: 259_200_000,
		translationKey: 'month'
	},
	d: {
		coef: 86_400_000,
		translationKey: 'day'
	},
	h: {
		coef: 3_600_000,
		translationKey: 'hour'
	},
	m: {
		coef: 60_000,
		translationKey: 'minute'
	},
	s: {
		coef: 1000,
		translationKey: 'second'
	}
};

export function formatDuration(
	milliseconds: number,
	format: string = 'hm',
	style: 'long' | 'short' | 'raw' | {separator: string} = 'short'
) {
	const values = [];
	for (const f of format.split('')) {
		const duration = DURATION_MAP[f as keyof typeof DURATION_MAP];
		const value = Math.floor(milliseconds / duration.coef);
		let label = get(t)(getPluralKey(duration.translationKey, value));
		label = style === 'long' ? label : label[0];
		milliseconds %= duration.coef;
		values.push([value, label]);
	}

	if (style === 'raw') {
		return values;
	} else if (typeof style === 'object') {
		return values.map((v) => v[0]).join(style.separator);
	} else if (style === 'long') {
		return values.map((v) => v.join(' ')).join(' ');
	} else {
		return values.map((v) => v.join('')).join(' ');
	}
}

export function sortMostRecent(
	data: AllData,
	sortField: 'createdAt',
	numberOfItems: number
): NotConsultedItem[] {
	const flat = [...data.invoices, ...data.contracts, ...data.sales];
	const sorted = flat.sort((a: NotConsultedItem, b: NotConsultedItem) => {
		const dateA = a[sortField];
		const dateB = b[sortField];
		return new Date(dateB).getTime() - new Date(dateA).getTime();
	});

	return sorted.slice(0, numberOfItems);
}

export function formatDateAndHalfDay(
	date: Date | string,
	halfDay: string | undefined
): string {
	if (typeof date === 'string') {
		date = new Date(date);
	}
	const $t = get(t);
	const formattedDate = formatDate(
		formatDateToISO(date),
		'eeee dd MMMM yyyy'
	);

	if (halfDay === 'morning') {
		return `${$t(translations.widget.forTheMorningOf)} ${formattedDate}`;
	} else if (halfDay === 'afternoon') {
		return `${$t(translations.widget.forTheAfternoonOf)} ${formattedDate}`;
	} else {
		return `${$t(translations.global.for)} ${formattedDate}`;
	}
}
